import {
  SET_LAYOUT_SETTINGS,
  SET_DEFAULT_LAYOUT_SETTINGS,
  SET_ADMIN_CHOOSE,
  SET_IS_CALL_NOTIFICATION,
  SET_CURRENT_ORGANIZATION_PLAN,
  SET_NAVIGATIONS,
} from '../actions/LayoutActions';
import { GullLayoutSettings } from '../../GullLayout/settings';
// import {navigations} from '../../../app/navigations'

const initialState = {
  settings: {
    ...GullLayoutSettings,
  },
  defaultSettings: {
    ...GullLayoutSettings,
  },
  currentOrganizationPlan: null,
  navigations: [],
  adminChoose: '',
  isCallNotification: false,
};

const LayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_SETTINGS:
      return {
        ...state,
        settings: { ...action.data },
      };
    case SET_DEFAULT_LAYOUT_SETTINGS:
      return {
        ...state,
        defaultSettings: { ...action.data },
      };
    case SET_ADMIN_CHOOSE:
      return {
        ...state,
        adminChoose: { ...action.data },
      };
    case SET_IS_CALL_NOTIFICATION:
      return {
        ...state,
        isCallNotification: action.data,
      };
    case SET_CURRENT_ORGANIZATION_PLAN: 
      return {
        ...state,
        currentOrganizationPlan: action.data,
      };
    case SET_NAVIGATIONS: 
      return {
        ...state,
        navigations: action.data
      }
    default:
      return { ...state };
  }
};

export default LayoutReducer;
