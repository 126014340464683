import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const Employees = lazy(() => import('./Employees'));
const EmployeeCreate = lazy(() => import('./EmployeeCreate'));
const PersonalDetail = lazy(() => import('./EmployeeDetail/PersonalDetail'));
const EmploymentPayDetails = lazy(() => import('./EmployeeDetail/EmploymentPayDetails'));
const PayslipDetails = lazy(() => import('./EmployeeDetail/PayslipDetails'));
const AssetsDetails = lazy(() => import('./EmployeeDetail/AssetsDetails'));
const AttendanceLog = lazy(() => import('./EmployeeDetail/AttendanceLog'));
const ClaimsDetails = lazy(() => import('./EmployeeDetail/ClaimsDetails'));
const LeaveDetails = lazy(() => import('./EmployeeDetail/LeaveDetails'));
const AppraisalDetails = lazy(() => import('./EmployeeDetail/AppraisalDetails'));
const ChangePassword = lazy(() => import('./EmployeeDetail/ChangePassword'));

const employeesRoutes = [
  {
    path: '/employees',
    component: Employees,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/create',
    component: EmployeeCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/:id/personal-details',
    component: PersonalDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/:id/employment-pay-details',
    component: EmploymentPayDetails,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/:id/payslip-details',
    component: PayslipDetails,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/:id/assets-details',
    component: AssetsDetails,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/:id/attendance-logs',
    component: AttendanceLog,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/:id/claims-details',
    component: ClaimsDetails,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/:id/leave-details',
    component: LeaveDetails,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/:id/appraisal-details',
    component: AppraisalDetails,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/employees/:id/change-password',
    component: ChangePassword,
    auth: authRoles.admin,
    exact: true,
  },
];

export default employeesRoutes;
