import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const PaySlipList = lazy(() => import('./PaySlip/PayrollList'));
const PaySlipDetail = lazy(() => import('./PaySlip/PayslipView'));

const payrollRoutes = [
  {
    path: '/staff-payslip',
    component: PaySlipList,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-payslip/:id',
    component: PaySlipDetail,
    auth: authRoles.admin,
    exact: true,
  },
];

export default payrollRoutes;
