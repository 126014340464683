import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const ManageAdmin = lazy(() => import('./ManageAdmin'));
const CreateUserAdmin = lazy(() => import('./CreateUserAdmin'));
const ViewAdmin = lazy(() => import('./ViewAdmin'));

const manageAdminRoutes = [
  {
    path: '/manage-admin',
    component: ManageAdmin,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/manage-admin/create',
    component: CreateUserAdmin,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/manage-admin/:id',
    component: ViewAdmin,
    auth: authRoles.sa,
    exact: true,
  },
];

export default manageAdminRoutes;
