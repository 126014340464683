import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const Assets = lazy(() => import('./Assets'));
const AssetDetail = lazy(() => import('./AssetDetail'));
const AssetCreate = lazy(() => import('./AssetCreate'));

const assetRoutes = [
  {
    path: '/assets',
    component: Assets,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/assets/create',
    component: AssetCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/assets/:id',
    component: AssetDetail,
    auth: authRoles.admin,
    exact: true,
  },
];

export default assetRoutes;
