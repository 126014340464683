import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const RoomBookingScheduleList = lazy(() => import('./room-booking-schedule/List'));
const RoomBookingScheduleCreate = lazy(() => import('./room-booking-schedule/Create'));
const RoomBookingScheduleEdit = lazy(() => import('./room-booking-schedule/Edit'));
const ManageRoomList = lazy(() => import('./manage-room/List'));
const ManageRoomCreate = lazy(() => import('./manage-room/Create'));
const RoomBookingCalender = lazy(() => import('./room-booking-schedule/RoomBookingCalender'));
const RoomBookingCalenderEdit = lazy(() => import('./room-booking-schedule/RoomBookingCalenderEdit'));
const ManageRoomEdit = lazy(() => import('./manage-room/Edit'));
const RoomBookingSetting = lazy(() => import('./Setting'));

const roomBookingRoutes = [
  {
    path: '/room-booking/room-booking-schedule',
    component: RoomBookingScheduleList,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/room-booking/room-booking-schedule/create',
    component: RoomBookingCalender,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/room-booking/room-booking-schedule/:id/edit',
    component: RoomBookingCalenderEdit,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/room-booking/manage-room',
    component: ManageRoomList,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/room-booking/manage-room/create',
    component: ManageRoomCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/room-booking/manage-room/:id/edit',
    component: ManageRoomCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/room-booking/setting',
    component: RoomBookingSetting,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/room-booking',
    exact: true,
    component: () => <Redirect to="/room-booking/room-booking-schedule" />,
  },
];

export default roomBookingRoutes;
