import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const ShiftBoard = lazy(() => import('./board/ShiftBoard.jsx'));
const ShiftTemplate = lazy(() => import('./template/ShiftTemplate.jsx'));
const NewShiftTemplate = lazy(() => import('./template/NewShiftTemplate.jsx'));
const EditShiftTemplate = lazy(() => import('./template/EditShiftTemplate.jsx'));

const shiftPlanRoutes = [
  {
    path: '/shift-planning/board',
    component: ShiftBoard,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/shift-planning/template/edit/:id',
    component: NewShiftTemplate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/shift-planning/template/create',
    component: NewShiftTemplate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/shift-planning/template',
    component: ShiftTemplate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/shift-planning',
    exact: true,
    component: () => <Redirect to="/shift-planning/board" />,
  },
];

export default shiftPlanRoutes;
