import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const LeaveSummary = lazy(() => import('./Summary/LeavesSummary'));
const LeaveCreate = lazy(() => import('./Summary/LeaveCreate'));
const LeaveEdit = lazy(() => import('./Summary/LeaveEdit'));
const LeaveDetail = lazy(() => import('./Summary/LeaveDetail'));

const LeaveTypes = lazy(() => import('./Types/LeaveType'));
const LeaveTypesCreate = lazy(() => import('./Types/LeaveTypeCreate'));
const LeaveTypeEdit = lazy(() => import('./Types/LeaveTypeEdit'));

const LeaveSetting = lazy(() => import('./Setting/LeaveSetting'));

const leavesRoutes = [
  {
    path: '/leaves/leaves-summary',
    component: LeaveSummary,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/leaves/leaves-summary/create',
    component: LeaveCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/leaves/leaves-summary/:id',
    component: LeaveDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/leaves/leaves-summary/edit/:id',
    component: LeaveEdit,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/leaves/leaves-types',
    component: LeaveTypes,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/leaves/leaves-types/create',
    component: LeaveTypesCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/leaves/leaves-types/edit/:id',
    component: LeaveTypeEdit,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/leaves/leaves-setting',
    component: LeaveSetting,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/leaves',
    exact: true,
    component: () => <Redirect to="/leaves/leaves-summary" />,
  },
];

export default leavesRoutes;
