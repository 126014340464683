import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const ManageEntities = lazy(() => import('./ManageEntities'));
const CreateEntity = lazy(() => import('./CreateEntity'));
const ViewEntity = lazy(() => import('./ViewEntity'));
const Employees = lazy(() => import('./Employees'));
const PlansBilling = lazy(() => import('./PlansBilling'));

const ManageEntitiesRoutes = [
  {
    path: '/manage-entities',
    component: ManageEntities,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/manage-entities/create',
    component: CreateEntity,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/manage-entities/:id/information',
    component: ViewEntity,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/manage-entities/:id/employees',
    component: Employees,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/manage-entities/:id/plans-billing',
    component: PlansBilling,
    auth: authRoles.sa,
    exact: true,
  },
];

export default ManageEntitiesRoutes;
