import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const DeskBookingSchedule = lazy(() => import('./Schedule/DeskBookingSchedule'));
const DeskBookingScheduleCreate = lazy(() => import('./Schedule/DeskBookingCalender'));
const DeskBookingScheduleEdit = lazy(() => import('./Schedule/DeskBookingCalenderEdit'));
const DeskBookingScheduleView = lazy(() => import('./Schedule/DeskBookingScheduleView'));

const deskBookingRoutes = [
  {
    path: '/staff-desk-booking',
    component: DeskBookingSchedule,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-desk-booking/create',
    component: DeskBookingScheduleCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-desk-booking/edit/:id',
    component: DeskBookingScheduleEdit,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-desk-booking/view/:id',
    component: DeskBookingScheduleView,
    auth: authRoles.admin,
    exact: true,
  },
];

export default deskBookingRoutes;
