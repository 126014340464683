import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const SaDashboard = lazy(() => import('./index'));

const dashboardRoutes = [
  {
    path: '/sa-dashboard',
    component: SaDashboard,
    auth: authRoles.sa,
    exact: true,
  },
];

export default dashboardRoutes;
