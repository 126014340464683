import Mock from '../mock';
import shortId from 'shortid';

const NotificationDB = {
  list: [
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'A new organization Asure Pro has been created',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'An Asst. Admin Account John Doe has been deleted',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'A new organization Asure Pro has been created',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'An Asst. Admin Account John Doe has been deleted',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'A new organization Asure Pro has been created',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'An Asst. Admin Account John Doe has been deleted',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'A new organization Asure Pro has been created',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'An Asst. Admin Account John Doe has been deleted',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'A new organization Asure Pro has been created',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'An Asst. Admin Account John Doe has been deleted',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'A new organization Asure Pro has been created',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'An Asst. Admin Account John Doe has been deleted',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'A new organization Asure Pro has been created',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Organization',
      content: 'An Asst. Admin Account John Doe has been deleted',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
    {
      id: shortId.generate(),
      type: 'Employees',
      content: 'A new employee John Doe has been onboarded',
      createdAt: 1570702802573,
    },
  ],
};

Mock.onGet('/api/notification').reply((config) => {
  const response = NotificationDB.list;
  return [200, response];
});

Mock.onGet('/api/notification-detail').reply((config) => {
  const response = NotificationDB.list[0];
  return [200, response];
});

Mock.onPost('/api/notification/add').reply((config) => {
  const response = NotificationDB.list;
  return [200, response];
});

Mock.onPost('/api/notification/delete').reply((config) => {
  let { id } = JSON.parse(config.data);
  console.log(id);

  const response = NotificationDB.list.filter((notification) => notification.id !== id);
  NotificationDB.list = [...response];
  return [200, response];
});

Mock.onPost('/api/notification/delete-all').reply((config) => {
  NotificationDB.list = [];
  const response = NotificationDB.list;
  return [200, response];
});
