import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const PlansBilling = lazy(() => import('./PlansBilling'));
const ManagePlans = lazy(() => import('./ManagePlans'));
const PlanSubscriptions = lazy(() => import('./PlanSubscriptions'));

const managePlanRoutes = [
  {
    path: '/plan',
    component: PlansBilling,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/plan/change',
    component: ManagePlans,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/plan/subscriptions/:id',
    component: PlanSubscriptions,
    auth: authRoles.admin,
    exact: true,
  },
];

export default managePlanRoutes;
