import { lazy } from 'react';

const AuthGoogleRedirect = lazy(() => import('./AuthGoogleRedirect'));
const Error = lazy(() => import('./Error'));
const authRoutes = [
  {
    path: '/auth',
    component: AuthGoogleRedirect,
  },
  {
    path: '/login-google-fail',
    component: Error,
  },
];

export default authRoutes;
