import { combineReducers } from 'redux';
import LoginReducer from './LoginReducer';
import UserReducer from './UserReducer';
import LayoutReducer from './LayoutReducer';
import ScrumBoardReducer from './ScrumBoardReducer';
import NotificationReducer from './NotificationReducer';
import DashboardReducer from './DashboardReducer';
import OrganizationReducer from './OrganizationReducer';
import employeeReducer from './EmployeeReducer';
import payrollReducer from './PayrollReducer';
import deskBookingReducer from './DeskBookingReducer';
import roomBookingReducer from './RoomBookingReducer';

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  dashboard: DashboardReducer,
  organization: OrganizationReducer,
  employees: employeeReducer,
  payroll: payrollReducer,
  deskBooking: deskBookingReducer,
  roomBooking: roomBookingReducer,
});

export default RootReducer;
