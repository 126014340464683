const layout3Settings = {
  leftSidebar: {
    open: false,
    mode: 'large', // large, close, compact
    theme: 'slateDark1', // View all valid theme colors inside GullTheme/themeColors.js
    bgOpacity: 0.96, // 0 ~ 1
    bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg',
  },
  header: {
    show: true,
    fixed: true,
    theme: 'white', // View all valid theme colors inside GullTheme/themeColors.js
  },
  searchBox: {
    open: false,
  },
  secondarySidebar: { show: true },
  footer: { show: true },
};

export default layout3Settings;
