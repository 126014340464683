import { SELECT_ORGANIZATION, SELECT_ORGANIZATION_SUCCESS } from '../actions/OrganizationAction.js';

const initialState = {
  selectedOrganization: {},
};

const OrganizationReducer = function (state = initialState, action) {
  switch (action.type) {
    case SELECT_ORGANIZATION:
      return (state = {
        ...state,
      });

    case SELECT_ORGANIZATION_SUCCESS:
      return (state = {
        ...state,
        selectedOrganization: action.payload.data,
      });

    default: {
      return state;
    }
  }
};

export default OrganizationReducer;
