import { GET_CALENDAR } from '../actions/DashboardAction';

const initialState = {};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALENDAR:
      return {
        ...state,
        ...action.data,
      };
    default:
      return { ...state };
  }
};

export default DashboardReducer;
