import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const Notification = lazy(() => import('./Notification'));
const NotificationDetail = lazy(() => import('./NotificationDetail'));

const notificationRoutes = [
  {
    path: '/staff-notification',
    component: Notification,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-notification/:id',
    component: NotificationDetail,
    exact: true,
  },
];

export default notificationRoutes;
