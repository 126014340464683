import React from 'react';

/* @return A timezone offset in minutes */
const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};
const CustomTimeGutterHeader = ({ employee }) => {
  const gmtOffset = getOffset(employee?.timezone) / 60; // Tính độ lệch GMT hiện tại
  const gmtLabel = `GMT${gmtOffset >= 0 ? '+' : ''}${gmtOffset}`;

  return (
    <div className="gutter-header">
      <div>{gmtLabel}</div>
    </div>
  );
};

export default CustomTimeGutterHeader;
