import axios from './axios';
import localStorageService from './localStorageService';
import jwt_decode from 'jwt-decode';

class JwtAuthService {
  user = {
    userId: '1',
    role: 'Admin',
    displayName: 'Asure HR',
    email: 'executive@asure.pro',
    photoURL: '/assets/images/face-7.jpg',
    age: 25,
    token: 'faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh',
  };

  loginWithEmailAndPassword = (email, password) => {
    return axios
      .post(
        '/api/v1/auth/login',
        { email: email, password: password },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      .then((res) => {
        var token = res.data.data.access_token;
        var jwtDecode = jwt_decode(token);
        localStorage.setItem('OrganizationId', jwtDecode?.user?.organizationId || '');
        this.setSession(token);
        this.setUser(jwtDecode.user);
        return jwtDecode.user;
      });
  };

  loginEmployerWithEmailAndPassword = (email, password) => {
    return axios
      .post(
        '/api/v1/employee/login',
        { email: email, password: password},
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      .then((res) => {
        var token = res.data.data.access_token;
        var jwtDecode = jwt_decode(token);
        localStorage.setItem('OrganizationId', jwtDecode?.user?.organizationId);
        this.setSession(token);
        this.setUser(jwtDecode.user);
        return jwtDecode.user;
      });
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then((data) => {
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  setSession = (token) => {
    if (token) {
      localStorage.setItem('jwt_token', token);
      axios.defaults.headers['Authorization'] = 'Bearer ' + token;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('OrganizationId');
      delete axios.defaults.headers['Authorization'];
      delete axios.defaults.headers.common['Authorization'];
    }
  };
  jwtDecode = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };
  setUser = (user) => {
    localStorageService.setItem('auth_user', user);
  };
  removeUser = () => {
    localStorage.removeItem('auth_user');
  };
}

export default new JwtAuthService();
