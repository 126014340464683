import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const AttendanceLoglist = lazy(() => import('./Loglist/AttendanceLoglist.jsx'));
const AttendanceSummary = lazy(() => import('./Summary/AttendanceSummary.jsx'));
const AttendanceAudits = lazy(() => import('./Audits/AttendanceAudits.jsx'));
const AttendanceSettings = lazy(() => import('./Settings/AttendanceSettings.jsx'));
const AddAttendance = lazy(() => import('./Loglist/AddAttendance.jsx'));
const AttendanceLog = lazy(() => import('./Loglist/AttendanceLog.jsx'));
const AttendanceLogEmployee = lazy(() => import('./Summary/AttendanceLogEmployee.jsx'));
const EditAttendance = lazy(() => import('./Loglist/EditAttendance.jsx'));

const attendanceRoutes = [
  {
    path: '/attendance/log-list/create',
    component: AddAttendance,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/attendance/log-list/edit/:id',
    component: EditAttendance,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/attendance/log-list/:id',
    component: AttendanceLog,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/attendance/log-list',
    component: AttendanceLoglist,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/attendance/summary/:employee',
    component: AttendanceLogEmployee,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/attendance/summary',
    component: AttendanceSummary,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/attendance/audits',
    component: AttendanceAudits,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/attendance/settings',
    component: AttendanceSettings,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/attendance',
    exact: true,
    component: () => <Redirect to="/attendance/log-list" />,
  },
];

export default attendanceRoutes;
