import { SET_PAYROLL_EMPLOYEE_GENERATE } from '../actions/PayrollActions';

const initialState = {};

const payrollReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_PAYROLL_EMPLOYEE_GENERATE: {
      return {
        ...state,
        ...action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default payrollReducer;
