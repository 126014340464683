import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const AppChat = lazy(() => import('./AppChat'));

const chatRoutes = [
  {
    path: '/chat',
    component: AppChat,
    auth: authRoles.admin,
  },
];

export default chatRoutes;
