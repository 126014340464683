import { SET_EMPLOYEE_DATA } from "../actions/EmployeeActions";

const initialState = {};

const employeeReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEE_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default employeeReducer;
