import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const ShiftBoard = lazy(() => import('./board/ShiftBoard.jsx'));

const shiftPlanRoutes = [
  {
    path: '/staff-shift-schedule',
    component: ShiftBoard,
    auth: authRoles.admin,
    exact: true,
  },
];

export default shiftPlanRoutes;
