import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const ClaimsSummary = lazy(() => import('./Summary/ClaimsSummary'));
const AddClaim = lazy(() => import('./Summary/AddClaim'));
const ClaimDetail= lazy(() => import('./Summary/ClaimDetail'));
const EditClaim = lazy(() => import('./Summary/EditClaim'));

const claimsRoutes = [
  {
    path: '/staff-claims/create',
    component: AddClaim,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-claims/:id',
    component: ClaimDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-claims/:id/edit',
    component: EditClaim,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-claims',
    component: ClaimsSummary,
    auth: authRoles.admin,
    exact: true,
  },
];

export default claimsRoutes;
