export const SET_NEW_EVENT_DATA = 'SET_NEW_EVENT_DATA';

export function setNewEventDataToReducer(newEventData) {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_EVENT_DATA,
      data: newEventData,
    });
  };
}
