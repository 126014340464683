import { lazy } from 'react';

const UserProfile = lazy(() => import('./UserProfile'));
const EmployeeProfile = lazy(() => import('./EmployeeProfile'));
const EmployeeChangePass = lazy(() => import('./ChangePass'));
const ProfileChangePass = lazy(() => import('./ProfileChangePass'));

const pagesRoutes = [
  {
    path: '/pages/user-profile',
    component: UserProfile,
    exact: true,
  },
  {
    path: '/pages/employee-profile',
    component: EmployeeProfile,
  },
  {
    path: '/pages/employee-change-password',
    component: EmployeeChangePass,
  },
  {
    path: '/pages/user-profile/change-password',
    component: ProfileChangePass,
    exact: true,
  },
];

export default pagesRoutes;
