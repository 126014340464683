import { lazy } from 'react';

const Signin = lazy(() => import('./Signin'));

const ForgotPassword = lazy(() => import('./ForgotPassword'));

const EnterResetCode = lazy(() => import('./EnterResetCode'));

const ResetPassword = lazy(() => import('./ResetPassword'));

const SASessionsRoutes = [
  {
    path: '/supper-admin/signin',
    component: Signin,
  },
  {
    path: '/supper-admin/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/supper-admin/enter-reset-code',
    component: EnterResetCode,
  },
  {
    path: '/supper-admin/reset-password',
    component: ResetPassword,
  },
];

export default SASessionsRoutes;
