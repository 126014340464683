import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const Finance = lazy(() => import('./index'));
const EditFinance = lazy(() => import('./EditFinance'));
const ViewFinance = lazy(() => import('./index'));

const financeRoutes = [
  {
    path: '/finance',
    component: Finance,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/finance/:id/edit',
    component: EditFinance,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/finance/:id/view',
    component: ViewFinance,
    auth: authRoles.sa,
    exact: true,
  },
];

export default financeRoutes;
