import { SET_NEW_EVENT_DATA } from '../actions/DeskBookingActions';

const initialState = { newEvent: null };

const deskBookingReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_NEW_EVENT_DATA: {
      return {
        ...state,
        newEvent: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default deskBookingReducer;
