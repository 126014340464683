import React from 'react';
import moment from 'moment';

const CustomHeader = ({ date }) => {
  const formattedDate = moment(date);
  return (
    <div>
      <div>{formattedDate.format('ddd')}</div> {/* Hiển thị thứ (Mon, Tue, ...) */}
      <div>{formattedDate.format('DD')}</div> {/* Hiển thị ngày (26 May) */}
    </div>
  );
};

export default CustomHeader;
