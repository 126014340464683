export const SET_EMPLOYEE_DATA = 'SET_EMPLOYEE_DATA';

export function setEmployeeDataToReducer(employeeData) {
  return (dispatch) => {
    dispatch({
      type: SET_EMPLOYEE_DATA,
      data: employeeData,
    });
  };
}

