import Swal from 'sweetalert2';

export const fireSignInAlert= (message =  'Oops there is some error. Please try again') => {
  Swal.fire({
    title: null,
    html: `
      <div style="display: flex; gap: 10px; align-items: center; padding: 10px; justify-content: center;">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 7L12 13" stroke="#082626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 17.01L12.01 16.9989" stroke="#082626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#082626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <div style="font-size: 16px; color: #082626">${message}</div>
      </div>
      `,
    showConfirmButton: false,
    backdrop: 'transparent',
    timer: 3000,
    showCloseButton: false,
    closeButtonHtml: '<span class="kelick-icon-close"></span>',
    customClass: {
      popup: 'kelick-alert',
    },
    showClass: {
      popup: 'swal2-show',
    },
    hideClass: {
      popup: 'swal2-hide',
    },
  });
};
