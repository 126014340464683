import '../fake-db';
import React, { Suspense } from 'react';
import '../styles/app/app.scss';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import AppContext from './appContext';
import history from '@history';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import routes from './RootRoutes';
import { Store } from './redux/Store';
import { renderRoutes } from 'react-router-config';
import Auth from './auth/Auth';
import RootRoutes from './RootRoutes';
import { Loading } from '@gull';

function App() {
  const queryClient = new QueryClient();

  return (
    <AppContext.Provider value={{ routes }}>
      <QueryClientProvider client={queryClient}>
        <Provider store={Store}>
          <Auth>
            <Suspense fallback={<Loading></Loading>}>
              <Router history={history}>{renderRoutes(RootRoutes)}</Router>
            </Suspense>
          </Auth>
        </Provider>
      </QueryClientProvider>
    </AppContext.Provider>
  );
}

export default App;
