import { lazy } from 'react';

const Signup = lazy(() => import('./Signup'));

const SetupOrg = lazy(() => import('./SetupOrg'));

const Signin = lazy(() => import('./Signin'));

const ForgotPassword = lazy(() => import('./ForgotPassword'));

const EnterResetCode = lazy(() => import('./EnterResetCode'));

const ResetPassword = lazy(() => import('./ResetPassword'));

const Error404 = lazy(() => import('./Error'));

const sessionsRoutes = [
  {
    path: '/session/signup',
    component: Signup,
  },
  {
    path: '/session/login',
    component: Signin,
  },
  // {
  //   path: '/session/login',
  //   component: Signin,
  // },
  {
    path: '/session/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/session/enter-reset-code',
    component: EnterResetCode,
  },
  {
    path: '/session/reset-password',
    component: ResetPassword,
  },
  {
    path: '/session/404',
    component: Error404,
  },
  {
    path: '/session/continueSignUp',
    component: SetupOrg,
  }
];

export default sessionsRoutes;
