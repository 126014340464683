import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const Support = lazy(() => import('./index'));
const ViewSupport = lazy(() => import('./ViewSupport'));
const CreateTicket = lazy(() => import('./create/index'));

const supportRoutes = [
  {
    path: '/support',
    component: Support,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/support/create',
    component: CreateTicket,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/support/:id/view',
    component: ViewSupport,
    auth: authRoles.admin,
    exact: true,
  },
];

export default supportRoutes;
