export const navigations = [
  {
    name: 'Dashboard',
    path: '/staff-dashboard',
    module: 'dashboard',
    icon: 'dashboard',
    iconType: 'custom',
  },
  {
    name: 'Notification',
    path: '/staff-notification',
    icon: 'notification',
    module: 'notification',
    iconType: 'custom',
  },
  {
    name: 'Payslip',
    path: '/staff-payslip',
    icon: 'payroll',
    module: 'payroll',
    iconType: 'custom',
  },
  {
    name: 'Attendance',
    path: '/staff-attendance',
    icon: 'attendance',
    module: 'attendance',
    iconType: 'custom',
  },
  {
    name: 'Claims',
    path: '/staff-claims',
    icon: 'claims',
    module: 'claims',
    iconType: 'custom',
  },
  {
    name: 'Leaves',
    path: '/staff-leaves',
    icon: 'leaves',
    module: 'leaves',
    iconType: 'custom',
  },
  {
    name: 'Appraisal',
    path: '/staff-appraisal',
    icon: 'appraisal',
    module: 'appraisal',
    iconType: 'custom',
  },
  {
    name: 'Shift Schedule',
    path: '/staff-shift-schedule',
    icon: 'shift-planning',
    module: 'shift',
    iconType: 'custom',
  },
  {
    name: 'Desk-Booking',
    path: '/staff-desk-booking',
    icon: 'desk-booking',
    module: 'desk_booking',
    iconType: 'custom',
  },
  {
    name: 'Room Booking',
    path: '/staff-room-booking',
    icon: 'room-booking',
    module: 'room_booking',
    iconType: 'custom',
  },
  {
    name: 'Chat',
    path: '/chat',
    icon: 'chat',
    module: 'chat',
    iconType: 'custom',
  },
];
