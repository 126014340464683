import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const LeaveSummary = lazy(() => import('./Summary/LeavesSummary'));
const LeaveCreate = lazy(() => import('./Summary/LeaveCreate'));
const LeaveEdit = lazy(() => import('./Summary/LeaveEdit'));
const LeaveDetail = lazy(() => import('./Summary/LeaveDetail'));

const leavesRoutes = [
  {
    path: '/staff-leaves',
    component: LeaveSummary,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-leaves/create',
    component: LeaveCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-leaves/:id',
    component: LeaveDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-leaves-summary/edit/:id',
    component: LeaveEdit,
    auth: authRoles.admin,
    exact: true,
  }, 
];

export default leavesRoutes;
