import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const Roles = lazy(() => import('./Roles/Roles'));
const RoleCreate = lazy(() => import('./Roles/RoleCreate'));
const Candidates = lazy(() => import('./Roles/RoleDetail/Candidates'));
const RoleDetail = lazy(() => import('./Roles/RoleDetail/RoleDetail'));
const ScreeningQuestions = lazy(() => import('./Roles/RoleDetail/ScreeningQuestions'));
const CandidatesOverview = lazy(() => import('./CandidatesOverview/CandidatesOverview'));
const HiringProcess = lazy(() => import('./HiringProcess/HiringProcess'));
const TalentPool = lazy(() => import('./TalentPool/TalentPool'));

const interviewRoutes = [
  {
    path: '/interview/roles',
    component: Roles,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/interview/roles/create',
    component: RoleCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/interview/roles/:id/candidates',
    component: Candidates,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/interview/roles/:id/role-detail',
    component: RoleDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/interview/roles/:id/screenning-questions',
    component: ScreeningQuestions,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/interview/candidates-overview',
    component: CandidatesOverview,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/interview/hiring-process',
    component: HiringProcess,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/interview/talent-pool',
    component: TalentPool,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/interview',
    exact: true,
    component: () => <Redirect to="/interview/roles" />,
  },
];

export default interviewRoutes;
