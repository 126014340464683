import Mock from '../mock';
import shortId from 'shortid';

const RoomBookingDB = {
  room_booking_schedule: [
    {
      id: 1,
      booking_date: 1570702802573,
      booking_time: '09:00 - 10:00',
      branch_outlet: 'Asure HQ',
      room_name: 'Room 1',
      employee_booked: 'John Doe',
      status: 'Booked',
      images: [
        {
          id: 1,
          name: 'Main Meeting Room 01',
          url: 'https://img.freepik.com/free-vector/cartoon-hello-summer-illustration_23-2148941121.jpg?t=st=1680456975~exp=1680457575~hmac=bb27489a3a6802f08cc65f7095ab0043cb51fb1209dc1c02aaaeffb6163c3e64',
          detail: 'Asure Marketing Floor 1',
          tags: ['TV Display', 'Speaker', 'Stationaries', 'HTMI Cable', 'Mouse', 'Mousepad'],
          selected: 0,
        },
        {
          id: 2,
          name: 'Small Meeting Room 01',
          url: 'https://img.freepik.com/free-vector/gradient-hello-summer-sale-illustration_52683-61659.jpg?w=1800&t=st=1680457022~exp=1680457622~hmac=9ec0c3cb71dc93d20754d3b3fc704f83f2eb96ff30c4d8fbd5614026be713225',
          detail: 'Asure Marketing Floor 2',
          tags: [],
          selected: 0,
        },
        {
          id: 3,
          name: 'Conference Room 01',
          url: 'https://img.freepik.com/free-psd/summer-sale-up-60-percent-off-social-media-post-template_47987-14227.jpg?w=1380&t=st=1680457042~exp=1680457642~hmac=e64e5400df42377ee14d76ec83d94e0a0f0b5f556b608bf297ff9e0c3cdaf027',
          detail: 'Asure Marketing Floor 3',
          tags: [],
          selected: 0,
        },
      ],
    },
    {
      id: 2,
      booking_date: 1570702802573,
      booking_time: '09:00 - 10:00',
      branch_outlet: 'Asure HQ',
      room_name: 'Room 1',
      employee_booked: 'John Doe',
      status: 'Session In Progress',
    },
    {
      id: 3,
      booking_date: 1570702802573,
      booking_time: '09:00 - 10:00',
      branch_outlet: 'Asure HQ',
      room_name: 'Room 1',
      employee_booked: 'John Doe',
      status: 'Session Ended',
      images: [],
    },
    {
      id: 4,
      booking_date: 1570702802573,
      booking_time: '09:00 - 10:00',
      branch_outlet: 'Asure HQ',
      room_name: 'Room 1',
      employee_booked: 'John Doe',
      status: 'Booked',
      images: [],
    },
    {
      id: 5,
      booking_date: 1570702802573,
      booking_time: '09:00 - 10:00',
      branch_outlet: 'Asure HQ',
      room_name: 'Room 1',
      employee_booked: 'John Doe',
      status: 'Booked',
      images: [],
    },
    {
      id: 6,
      booking_date: 1570702802573,
      booking_time: '09:00 - 10:00',
      branch_outlet: 'Asure HQ',
      room_name: 'Room 1',
      employee_booked: 'John Doe',
      status: 'Booked',
      images: [],
    },
    {
      id: 7,
      booking_date: 1570702802573,
      booking_time: '09:00 - 10:00',
      branch_outlet: 'Asure HQ',
      room_name: 'Room 1',
      employee_booked: 'John Doe',
      status: 'Booked',
      images: [],
    },
  ],
  manager_room: [
    {
      id: 1,
      branch_outlet: 'Asure HQ',
      room_name: 'Main Meeting Room',
    },
    {
      id: 2,
      branch_outlet: 'Asure HQ',
      room_name: 'Small Meeting Room 01',
    },
    {
      id: 3,
      branch_outlet: 'Asure HQ',
      room_name: 'Small Meeting Room 02',
    },
    {
      id: 4,
      branch_outlet: 'Asure Auto',
      room_name: 'Main Meeting Room',
    },
    {
      id: 5,
      branch_outlet: 'Asure Insurance',
      room_name: 'Main Meeting Room',
    },
  ],
};

Mock.onGet('/api/room-booking/schedule').reply((config) => {
  return [200, RoomBookingDB.room_booking_schedule];
});

Mock.onGet('/api/room-booking-schedule/detail').reply((config) => {
  const id = config.data;
  const response = RoomBookingDB.room_booking_schedule.find((roomBooking) => roomBooking.id == id);
  return [200, response];
});

Mock.onGet('/api/manager-room').reply((config) => {
  return [200, RoomBookingDB.manager_room];
});

Mock.onGet('/api/manager-room/detail').reply((config) => {
  const id = config.data;
  const response = RoomBookingDB.manager_room.find((roomBooking) => roomBooking.id == id);
  return [200, response];
});
