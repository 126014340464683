import moment from 'moment';
import React from 'react';

const CustomToolbar = (props) => {
  const { date, view, onView, onNavigate } = props;

  const handleViewChange = (event) => {
    onView(event.target.value);
  };

  // Format the label for week view
  const formatLabel = () => {
    const start = moment(date).startOf('week');
    const end = moment(date).endOf('week');
    if (start.year() === end.year()) {
      return `${start.format('D MMM')} - ${end.format('D MMM YYYY')}`;
    } else {
      return `${start.format('D MMM YYYY')} - ${end.format('D MMM YYYY')}`;
    }
  };

  return (
    <div className="rbc-toolbar">
      <button className="btn-today" type="button" onClick={() => props.onNavigate('TODAY')}>
        Today
      </button>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => onNavigate('PREV')}>
          ‹
        </button>
        <button type="button" onClick={() => onNavigate('NEXT')}>
          ›
        </button>
      </span>
      <span className="rbc-toolbar-label">{view === 'week' ? formatLabel() : moment(date).format('D MMMM YYYY')}</span>
      <span className="rbc-btn-group">
        <select onChange={handleViewChange} value={view}>
          <option value="day">Day</option>
          <option value="week">Week</option>
        </select>
      </span>
    </div>
  );
};

export default CustomToolbar;
