import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const Notification = lazy(() => import('./Notification.jsx'));
const NotificationDetail = lazy(() => import('./NotificationDetail'));

const notificationRoutes = [
  {
    path: '/sa-notification',
    component: Notification,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/sa-notification/:id',
    component: NotificationDetail,
    auth: authRoles.sa,
  },
];

export default notificationRoutes;
