import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import RoomBookingCalender from './room-booking-schedule/RoomBookingCalender';
import RoomBookingScheduleEdit from './room-booking-schedule/RoomBookingScheduleEdit';

const RoomBookingScheduleList = lazy(() => import('./room-booking-schedule/List'));
const RoomBookingScheduleCreate = lazy(() => import('./room-booking-schedule/Create'));

const roomBookingRoutes = [
  {
    path: '/staff-room-booking',
    component: RoomBookingScheduleList,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-room-booking/create',
    component: RoomBookingCalender,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-room-booking/:id/edit',
    component: RoomBookingScheduleEdit,
    auth: authRoles.admin,
    exact: true,
  },
];

export default roomBookingRoutes;
