import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppContext from 'app/appContext';
import GullLayout from 'app/GullLayout/GullLayout';
import { flatMap } from 'lodash';
class AuthGuard extends Component {
  constructor(props, context) {
    super(props);
    let { routes } = context;

    this.state = {
      authenticated: true,
      accessed: true,
      role: '',
      routes,
    };
  }

  componentDidMount() {
    this.setState({
      routes: flatMap(this.state.routes, (item) => {
        if (item.routes) {
          return [...item.routes];
        }
        return [item];
      }),
    });

    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
    if (!this.state.accessed) {
      this.redirect404(this.props);
    }
    if (this.state.role === 'employee') {
      this.redirectStaffDashboard(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
    if (!this.state.accessed) {
      this.redirect404(this.props);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.authenticated !== this.state.authenticated;
  }

  static getDerivedStateFromProps(props, state) {
    const { location, user } = props;
    const { pathname } = location;
    const matched = state.routes.find((r) => r.path === pathname);
    const authenticated =
      matched && matched.auth && matched.auth.length ? matched.auth.includes((user.role || '').toLowerCase()) : true;

    /*check menu item is can accessed or not accessed*/
    let accessed = true;
    const preventAccess = user?.preventAccess;
    if (preventAccess && pathname) {
      const preventAccessMenus = preventAccess.filter((menu) => pathname.includes(menu));
      if (preventAccessMenus && preventAccessMenus.length > 0) {
        accessed = false;
      }
    }
    return {
      authenticated,
      accessed,
      role: user?.role,
    };
  }

  redirectRoute(props) {
    const { location, history } = props;
    const { pathname } = location;

    history.push({
      pathname: '/session/login',
      state: { redirectUrl: pathname },
    });
  }

  redirectStaffDashboard(props) {
    const { history } = props;

    history.push({
      pathname: '/staff-dashboard',
    });
  }

  redirect404(props) {
    const { history } = props;

    history.push({
      pathname: '/session/404',
    });
  }

  render() {
    let { route, user } = this.props;
    const { authenticated } = this.state;

    return authenticated ? (
      <Fragment>
        <GullLayout route={route} role={user?.role}></GullLayout>
      </Fragment>
    ) : null;
  }
}

AuthGuard.contextType = AppContext;

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(AuthGuard));
