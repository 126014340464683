import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const AppraisalSummary = lazy(() => import('./Summary/AppraisalSummary.jsx'));
const AppraisalSummaryCreate = lazy(() => import('./Summary/AppraisalSummaryCreate.jsx'));
const AppraisalSummaryDetails = lazy(() => import('./Summary/AppraisalSummaryDetail.jsx'));
const AppraisalTemplate = lazy(() => import('./Template/AppraisalTemplate.jsx'));
const AppraisalTemplateDefault = lazy(() => import('./Template/DefaultTemplate'));
const AppraisalTemplateCreate = lazy(() => import('./Template/AddTemplate'));
const AppraisalTemplateEdit = lazy(() => import('./Template/EditTemplate'));
const AppraisalTemplateDuplicate = lazy(() => import('./Template/DuplicateTemplate'));
const AppraisalTemplateView = lazy(() => import('./Template/ViewTemplate'));
const AppraisalPeriod = lazy(() => import('./Period/AppraisalPeriod.jsx'));
const AddPeriod = lazy(() => import('./Period/AddPeriod'));
const EditPeriod = lazy(() => import('./Period/EditPeriod.jsx'));

const claimsRoutes = [
  {
    path: '/appraisal/summary/:id',
    component: AppraisalSummaryDetails,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/summary/create',
    component: AppraisalSummaryCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/summary',
    component: AppraisalSummary,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/template',
    component: AppraisalTemplate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/template/default',
    component: AppraisalTemplateDefault,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/template/create',
    component: AppraisalTemplateCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/template/:id',
    component: AppraisalTemplateView,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/template/:id/edit',
    component: AppraisalTemplateEdit,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/template/:id/duplicate',
    component: AppraisalTemplateDuplicate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/period',
    component: AppraisalPeriod,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/period/create',
    component: AddPeriod,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/period/:id/edit',
    component: EditPeriod,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal/period/:id/view',
    component: EditPeriod,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/appraisal',
    exact: true,
    component: () => <Redirect to="/appraisal/summary" />,
  },
];

export default claimsRoutes;
