import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const AppraisalSummary = lazy(() => import('./Summary/AppraisalSummary.jsx'));
const AppraisalSummaryDetails = lazy(() => import('./Summary/AppraisalSummaryDetail.jsx'));

const claimsRoutes = [
  {
    path: '/staff-appraisal/summary/:id',
    component: AppraisalSummaryDetails,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/staff-appraisal',
    component: AppraisalSummary,
    auth: authRoles.admin,
    exact: true,
  },
];

export default claimsRoutes;
