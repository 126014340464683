import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const ClaimSummary = lazy(() => import('./Summary/ClaimSummary.jsx'));
const ClaimSettings = lazy(() => import('./Settings/ClaimSettings.jsx'));
const AddClaims = lazy(() => import('./Summary/AddClaims.jsx'));
const ClaimDetails = lazy(() => import('./Summary/ClaimDetail.jsx'));
const EditClaims = lazy(() => import('./Summary/EditClaim.jsx'));
const AddClaimType = lazy(() => import('./Types/AddClaimType.jsx'));
const EditClaimType = lazy(() => import('./Types/EditClaimType.jsx'));
const ClaimTypes = lazy(() => import('./Types/ClaimTypes.jsx'));
const LinkToPayroll = lazy(() => import('./LinkToPayroll/index.jsx'));

const claimsRoutes = [
  {
    path: '/claims/summary/create',
    component: AddClaims,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/claims/summary/:id/edit',
    component: EditClaims,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/claims/summary/:id',
    component: ClaimDetails,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/claims/summary',
    component: ClaimSummary,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/claims/types/add',
    component: AddClaimType,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/claims/types/:id/edit',
    component: EditClaimType,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/claims/settings',
    component: ClaimSettings,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/claims',
    exact: true,
    component: () => <Redirect to="/claims/summary" />,
  },
  {
    path: '/claims/types',
    component: ClaimTypes,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/claims/linkToPayroll',
    component: LinkToPayroll,
    auth: authRoles.admin,
    exact: true,
  },
];

export default claimsRoutes;
