import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';
import PayrollGenerationEdit from './PayrollList/PayrollGenerationEdit';

const PayrollList = lazy(() => import('./PayrollList/PayrollList'));
const PayrollDetail = lazy(() => import('./PayrollList/PayrollDetail'));
const PayslipView = lazy(() => import('./PayrollList/PayslipView'));
const PayItems = lazy(() => import('./PayItems/PayItems'));
const PayItemDetail = lazy(() => import('./PayItems/PayItemDetail'));
const PaySchemeTemplates = lazy(() => import('./PaySchemeTemplates/PaySchemeTemplates'));
const PaySchemeTemplateDetail = lazy(() => import('./PaySchemeTemplates/PaySchemeTemplateDetail'));
const PayrollSetting = lazy(() => import('./PayrollSetting/PayrollSetting'));
const PaymentPeriods = lazy(() => import('./PayrollSetting/PaymentPeriods'));
// const PaymentPeriodDetail = lazy(() => import('./PayrollSetting/PaymentPeriodDetail'));
// const PaymentPeriodEdit = lazy(() => import('./PayrollSetting/PaymentPeriodEdit'));
const GenerateNewPayroll = lazy(() => import('./PayrollList/GenerateNewPayroll'));
const AddEmployeesPayroll = lazy(() => import('./PayrollList/AddEmployeesPayroll'));
const AddEmployeesToPayrollConfirm = lazy(() => import('./PayrollList/AddEmployeesToPayrollConfirm'));
const PayrollGenerationConfirm = lazy(() => import('./PayrollList/PayrollGenerationConfirm'));
const YtdEmployeesSummaryReport = lazy(() => import('./ytd-summary-report/Index'));
const YtdSummaryEmployeeReportDetail = lazy(() => import('./ytd-summary-report/Detail'));

const payrollRoutes = [
  {
    path: '/payroll/list',
    component: PayrollList,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/ytd-summary-report',
    component: YtdEmployeesSummaryReport,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/ytd-summary-report/:id',
    component: YtdSummaryEmployeeReportDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/generate',
    component: GenerateNewPayroll,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/generate-confirm',
    component: PayrollGenerationConfirm,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/pay-items',
    component: PayItems,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/pay-items/:id',
    component: PayItemDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/pay-scheme-templates',
    component: PaySchemeTemplates,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/pay-scheme-templates/:id',
    component: PaySchemeTemplateDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/setting',
    component: PayrollSetting,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/setting/payment-periods',
    component: PaymentPeriods,
    auth: authRoles.admin,
    exact: true,
  },
  // {
  //   path: '/payroll/setting/payment-periods/:year',
  //   component: PaymentPeriodEdit,
  //   auth: authRoles.admin,
  //   exact: true,
  // },
  // {
  //   path: '/payroll/setting/payment-periods/:year/detail',
  //   component: PaymentPeriodDetail,
  //   auth: authRoles.admin,
  //   exact: true,
  // },
  {
    path: '/payroll/:id',
    component: PayrollDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/:id/add-employees',
    component: AddEmployeesPayroll,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/:id/add-employees/confirm',
    component: AddEmployeesToPayrollConfirm,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/:id/edit',
    component: PayrollGenerationEdit,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll/:id/payslip/:payslipId/view',
    component: PayslipView,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/payroll',
    exact: true,
    component: () => <Redirect to="/payroll/list" />,
  },
];

export default payrollRoutes;
