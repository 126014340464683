export const SET_LAYOUT_SETTINGS = 'LAYOUT_SET_SETTINGS';
export const SET_DEFAULT_LAYOUT_SETTINGS = 'LAYOUT_SET_DEFAULT_SETTINGS';
export const SET_ADMIN_CHOOSE = 'LAYOUT_SET_ADMIN_CHOOSE';
export const SET_IS_CALL_NOTIFICATION = 'LAYOUT_SET_IS_CALL_NOTIFICATION';
export const SET_CURRENT_ORGANIZATION_PLAN = 'SET_CURRENT_ORGANIZATION_PLAN';
export const SET_NAVIGATIONS = 'SET_NAVIGATIONS';

export const setLayoutSettings = (data) => (dispatch) => {
  dispatch({
    type: SET_LAYOUT_SETTINGS,
    data: data,
  });
};

export const setLayoutNavigations = (data) => (dispatch) => {
  dispatch({
    type: SET_NAVIGATIONS,
    data: data,
  });
};

export const setCurrentOrganization = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_ORGANIZATION_PLAN,
    data: data,
  });
};

export const setDefaultSettings = (data) => (dispatch) => {
  dispatch({
    type: SET_DEFAULT_LAYOUT_SETTINGS,
    data: data,
  });
};

export const setAdminChoose = (data) => (dispatch) => {
  dispatch({
    type: SET_ADMIN_CHOOSE,
    data: data,
  });
};

export const setIsCallNotification = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_CALL_NOTIFICATION,
    data: data,
  });
};
