import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

const DeskBookingSchedule = lazy(() => import('./Schedule/DeskBookingSchedule'));
const DeskBookingCalender = lazy(() => import('./Schedule/DeskBookingCalender'));
const DeskBookingCalenderEdit = lazy(() => import('./Schedule/DeskBookingCalenderEdit'));
const DeskBookingScheduleView = lazy(() => import('./Schedule/DeskBookingScheduleView'));

const Workspace = lazy(() => import('./Workspace/Workspace'));
const WorkspaceCreate = lazy(() => import('./Workspace/WorkspaceCreate'));
const WorkspaceEdit = lazy(() => import('./Workspace/WorkspaceEdit'));

const DeskBookingSetting = lazy(() => import('./Setting/BookingSetting'));

const deskBookingRoutes = [
  {
    path: '/desk-booking/schedule',
    component: DeskBookingSchedule,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/desk-booking/schedule/create',
    component: DeskBookingCalender,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/desk-booking/schedule/edit/:id',
    component: DeskBookingCalenderEdit,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/desk-booking/schedule/view/:id',
    component: DeskBookingScheduleView,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/desk-booking/workspace',
    component: Workspace,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/desk-booking/workspace/create',
    component: WorkspaceCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/desk-booking/workspace/edit/:id',
    component: WorkspaceEdit,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/desk-booking/setting',
    component: DeskBookingSetting,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/desk-booking',
    exact: true,
    component: () => <Redirect to="/desk-booking/schedule" />,
  },
];

export default deskBookingRoutes;
