import chameleon from '@chamaeleonidae/chmln';

const CHAMELEON_ID = process.env.REACT_APP_CHAMELEON_ID;

chameleon.init(CHAMELEON_ID, {
  fastUrl: 'https://fast.chameleon.io/'
});

export const identifyUser = (user) => {
  if (user && user.id) {
    chameleon.identify(user.id, {
      email: user.email,
      name: user.fullName
    });
  } else {
    console.error("User data is missing or incomplete");
  }
};
