import Mock from '../mock';
import shortId from 'shortid';

const ChatDB = {
  list_contact: [
    {
      id: '7863a6802ez0e277a0f98534',
      name: 'Asure HR',
      avatar: 'assets/images/faces/1.jpg',
      lastChatTime: 1570702802573,
      online: true,
    },
    {
      id: 1,
      name: 'Frank Powell',
      avatar: 'assets/images/faces/17.jpg',
      lastChatTime: 1570702802573,
      online: true,
    },
    {
      id: 2,
      name: 'Betty Diaz',
      avatar: 'assets/images/faces/2.jpg',
      lastChatTime: 1570702802573,
      online: true,
    },
    {
      id: 3,
      name: 'Brian Stephens',
      avatar: 'assets/images/faces/3.jpg',
      lastChatTime: 1570702802573,
      online: true,
    },
    {
      id: 4,
      name: 'Jacqueline Day',
      avatar: 'assets/images/faces/4.jpg',
      lastChatTime: 1570702802573,
      online: true,
    },
    {
      id: 5,
      name: 'Arthur Mendoza',
      avatar: 'assets/images/faces/5.jpg',
      lastChatTime: 1570702802573,
      online: true,
    },
    {
      id: 6,
      name: 'Jeremy Lee',
      avatar: 'assets/images/faces/9.jpg',
      lastChatTime: 1570702802573,
      online: true,
    },
  ],
  chats: [
    {
      id: '111',
      taget_user: 1,
      messageList: [
        {
          contactId: 1,
          name: 'Frank Powell',
          avatar: 'assets/images/faces/17.jpg',
          time: 1570702802573,
          text: 'Do you ever find yourself falling into the “discount trap?”',
        },
        {
          contactId: '7863a6802ez0e277a0f98534',
          name: 'Asure HR',
          avatar: 'assets/images/faces/1.jpg',
          time: 1570702802573,
          text: 'Giving away your knowledge or product just to gain clients?',
        },
        {
          contactId: 1,
          name: 'Frank Powell',
          avatar: 'assets/images/faces/17.jpg',
          time: 1570702802573,
          text: 'Yes',
        },
      ],
    },
  ],
};

Mock.onGet('/api/chat/contacts').reply((config) => {
  let response = null;
  ChatDB.list_contact.forEach((item) => {
    if (item.id == config.data) response = item;
  });
  return [200, response];
});

Mock.onGet('/api/chat/contacts/all').reply((config) => {
  const response = ChatDB.list_contact;
  return [200, response];
});

Mock.onGet('/api/chat/chat-room').reply((config) => {
  let data = JSON.parse(config.data);
  let chatContent = { chatId: 1, messageList: [], recentListUpdated: [] };
  ChatDB.chats.forEach((chat) => {
    if (chat.taget_user == data.contactId) {
      chatContent = chat;
    }
  });
  return [200, chatContent];
});
