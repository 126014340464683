import axios from 'axios';
import jwtAuthService from '../services/jwtAuthService';

const IGNORE_401 = ['api/v1/account/reset-password/otp', '/api/v1/auth/login', '/api/v1/employee/login'];

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('jwt_token'),
    OrganizationId: !!localStorage.getItem('OrganizationId') ? localStorage.getItem('OrganizationId') : '',
    AdminId: localStorage.getItem('AdminId') ?? '',
  },
});

instance.interceptors.request.use(async (request) => {
  request.headers.OrganizationId = localStorage.getItem('OrganizationId') || '';
  request.headers.AdminId = localStorage.getItem('AdminId') || '';
  return request;
});

instance.interceptors.response.use(
  function (response) {
    // Optional: Do something with response data
    return response;
  },
  function (error) {
    const isIgnore = IGNORE_401.includes(error?.response?.config?.url);
    if (error?.response?.status === 401 && !isIgnore) {
      jwtAuthService.logout();
      localStorage.clear();
      window.location.replace('/session/login');
    }
    return Promise.reject(error);
  }
);

export default instance;
